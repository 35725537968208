<script setup lang="ts">
import CoverageBanner from "./components/CoverageBanner.vue";
import { onMounted } from "vue";
import { useApplicationStore } from "@/stores/application";

const params = new URL(window.location as any).searchParams;

let referralId: string | null;
// if (params.get("referralId")) {
//   referralId = params.get("referralId");
// }

referralId = window.referralId;

onMounted(async () => {
  const applicationStore = useApplicationStore();
  await applicationStore.loadPlans();
  if (referralId) {
    console.log("Referral ID", referralId);
    applicationStore.$patch({ referralId });
  }
});
</script>
<template>
  <div>
    <CoverageBanner />
    <!-- 
      The `Suspense` api is used to allow async setup() methods 
      on route components. This may prove to be an anti-pattern 
      and need refactoring
    -->
    <Suspense>
      <RouterView />
    </Suspense>
  </div>
</template>