<script setup lang="ts">
import { useRouter } from "vue-router";
import { useApplicationStore } from "@/stores/application";

const router = useRouter();
const applicationStore = useApplicationStore();
const props = defineProps<{
  content: string;
  pricingContent: string;
  destinationLink: string;
  skipBYOStep: boolean;
  cta: string;
  image: string;
  offerMessage: string;
}>();


function checkZip() {
  //decide whether or not we should skip the "do you have a phone" question, store the decision
  applicationStore.$patch({
    destinationLink: props.destinationLink,
    skipBYOStep: props.skipBYOStep,
  });
  router.push({
    path: "/application",
  });
}
</script>

<template>
    <!-- wrapper -->
    <div class="promotion">
        <div class="offer-msg">{{ offerMessage }}</div>
        <div class="above-the-fold">
            <!-- image-->
            <div class="image">
                <!-- <img src="@/assets/media/sample/apple-iphone.png" /> -->
                <img :src="image">
            </div>
            <!-- content-->
            <div class="content">
                <!-- content body -->
                <div class="body">
                    <div v-html="content"></div>
                    <!-- {{ content }} : 
                        {{ cta }}
                        <h2>Samsung Galaxy A11</h2>
                        <h3>As low as</h3>
                        <p>promotional body</p> 
                        <p class="price" style="font-size:3rem; font-weight:bolder; color: var(--kw-blue)">$15/mo</p>-->
                </div>
                <!-- button-->
            </div>
        </div>
        <hr>
        <div class="below-the-fold">
            <div v-html="pricingContent"
                 class="below-the-fold-content"></div>
            <div class="cta">
                <button @click="checkZip()">{{ cta }}</button>
                <p class="disclaimer">
                    Limited time offer. New plan purchase is required. Taxes & shipping fees may apply. <a
                       href="/support/terms-and-conditions">See full terms</a>.

                </p>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.promotion {
    display:flex;
    flex-basis: 100%;
    @media (min-width:992px) {
        flex-basis:50%;
    }
    @media (max-width:992px) {
        max-height: none;
    }
    border: 0px solid black;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.20);
    max-height: 600px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: white;
    .offer-msg {
        background-color: #71BF34;
        padding: .35rem 1.25rem;
        justify-self:flex-start;
        text-transform: uppercase;
        color:#000;
        font-weight: bolder;
        border-bottom:3px solid #579129;
        min-width:33%;
        text-align: center;
        align-self: flex-start;
        margin-left:4.5rem;
        border-radius: 0 0 10px 10px;
    }
    .above-the-fold {
        display: flex;
        flex: 0 1 50%;
        gap: 20px;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem 0 1rem;
        flex-grow: 1;

        .image {
            
            img {
                object-fit: contain;
                max-height:250px;
                width:250px;
                height:250px;
                
            }
            flex: 1 1 0;
        }

        @media (max-width:992px) {
            flex-direction:column;
            gap:0;
            .image {
                
                max-height:150px;
                img {
                    
                    max-height:150px;
            
                }
            
            }
            
        }

        .content {
            display: flex;
            flex-direction: column;
            padding-top:2rem;
            padding-bottom:2rem;
            padding-left:.75rem;
            padding-right:.75rem;
            text-align: center;

            h1 {
                color: var(--kw-blue);
            }
            h1, h2 {
                font-size: 1.375rem;
            }
            h3 {
                font-size: 1.125rem !important;
            }

            p {
                strong {
                    color: var(--kw-blue);
                    font-size: 2.25rem;
                    margin: 10px auto;
                }
            }

            .body {
                flex: 1 1 0;
            }

            @media (max-width:992px) {
                padding-top:1rem;
                padding-bottom:1rem;
            }
        }
    }

    hr {
        margin: 10px auto !important;
        border-color: rgb(232, 232, 232) !important;
    }

    .below-the-fold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        margin: 0 auto;
        padding: 0 1.5rem 0.5rem 1.5rem;
        width: 100%;

        .below-the-fold-content {
            width: 100%;
        }

        * {
            margin: 0 auto;
        }

        .cta {
            text-align: center;
            width: 100%;
            padding: 0 3rem;

            .disclaimer {
                font-size: 12px !important;
                margin: 10px auto !important;
                font-style: italic;
            }
            @media (max-width:992px) {
                padding: 0 1rem;;
            }
        }

        table {
            border-collapse: collapse;
            margin: 10px auto 15px auto;
            width: 100%;

            tr {
                border: none;
                display: flex;
            }

            td:first-child {
                border-right: solid 1px rgb(232, 232, 232);
                text-align: right;
                padding-right: 10px;
                max-width: 50%;
                width: 50%;
            }

            td:nth-child(2) {
                padding-left: 10px;
                text-align: left;
                max-width: 50%;
                width: 50%;
            }
        }
    }

    

    

    button {
        background-color: var(--kw-red);
        width: 100%;

        @each $dir in 'top', 'right', 'bottom', 'left' {
            border-#{$dir}: 1px solid var(--kw-red);
        }
        &.outline {
            color: var(--kw-blue) !important;
        }
    }   
}
</style>