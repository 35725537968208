import { defineStore } from "pinia";
import type { Plan } from "@/interfaces/plan";

// API data
import { getPlans } from "@/api/api";
import type { Account } from "@/api/account.dto";
import type { OrderResponse } from "@/api/orderResponse.dto";
import type { Order } from "@/api/order.dto";

interface State {
  krSession?: {
    needSim: boolean;
    krogerReferralId: string;
    activNo: string;
  }; // grab this from the url on mount()
  referralId?: string; // grab this from url on mount()
  skipBYOStep: boolean;
  destinationLink: string;
  coverageZipcode: string;
  coverageZipcodeValid: boolean | null;
  availablePlans: Plan[];
  selectedAvailablePlanId: string | null;
  deviceImeiNumber: string | null;
  deviceIsCompatible: boolean | null;
  //account: any;
  account: {
    
    firstName: string;
    lastName: string;
    address1: string;
    address2:string;
    city: string;
    state:string;
    zipcode: string;
    email:string;
    emailConfirm:string;
    loyaltyNumber:string;
    password:string;
    passwordConfirm:string;
    selectedSecurityQuestion:string | null;
    selectedSecurityQuestionAnswer:string | null;
  }
  promoCode: string | null;
  hasVisitedBilling: boolean;
  billing: any;
  shipToBillingAddress: boolean;
  shipping: any;
  payment: any;
  paymentNonce: string | null;
  hasVisitedCart: boolean;
  termsAccepted: boolean;
  newsletterOptin: boolean;
  order: Order | null;
  orderDetails: OrderResponse | null;
  // API generated information required for order creation
  customerNumber: string | number | null;
  activationNumber: string | number | null;
  iccId: string | number | null;
  captchaVerification: {
      token: string | null,
      eKey : string | null
  }
}

export const useApplicationStore = defineStore("application", {
  state: (): State => ({
    referralId: "",
    destinationLink: import.meta.env.VITE_KW_SHOP_URL,
    skipBYOStep: false,
    coverageZipcode: "",
    coverageZipcodeValid: null,
    availablePlans: [],
    selectedAvailablePlanId: null,
    deviceImeiNumber: null,
    deviceIsCompatible: null,
    customerNumber: null,
    activationNumber: null,
    iccId: null,
    account: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      email: "",
      emailConfirm:"",
      loyaltyNumber: "",
      password: "",
      passwordConfirm: "",
      selectedSecurityQuestion: null,
      selectedSecurityQuestionAnswer: null,
    },
    promoCode : "",
    hasVisitedBilling: false,
    billing: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
      email: "",
      emailConfirm: "",
      loyaltyNumber: "",
    },
    shipToBillingAddress: true,
    shipping: {
      firstName: "",
      lastName: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zipcode: "",
    },
    payment: {
      ccNumber: "",
      ccExp: "",
      ccCvc: "",
      ccZipcode: "",
    },
    paymentNonce: null,
    hasVisitedCart: false,
    termsAccepted: false,
    newsletterOptin: false,
    order: null,
    orderDetails: null,
    captchaVerification: {
        token: null,
        eKey : null
    } 
  }),
  getters: {
    availablePlanTypes(state): string[] {
      return state.availablePlans
        ? state.availablePlans
            .map((p) => p.type)
            .filter((p, i, a) => a.indexOf(p) === i)
            .sort()
        : [];
    },
    accountValid(state): boolean {
      return (
        ![
          "firstName",
          "lastName",
          "address1",
          "city",
          "state",
          "zipcode",
          "email",
          "emailConfirm",
        ].some((key) => state.account[key] === "") &&
        state.account.email === state.account.emailConfirm
      );
    },
    accountSecurityValid(state): boolean {
      return [
        "password",
        "passwordConfirm",
        "selectedSecurityQuestion",
        "selectedSecurityQuestionAnswer",
      ].some((key) => state.account[key] === "" || state.account[key] === null)
        ? false
        : state.account.password === state.account.passwordConfirm &&
            state.account.password.length >= 4 && state.account.password.length <= 6; // minimum 6 chars
    },
    billingValid(state): boolean {
      return ![
        "firstName",
        "lastName",
        "address1",
        "city",
        "state",
        "zipcode",
        "email",
      ].some((key) => state.billing[key] === "");
    },
    shippingValid(state): boolean {
      if (state.shipToBillingAddress) {
        return true;
      }
      return ![
        "firstName",
        "lastName",
        "address1",
        "city",
        "state",
        "zipcode",
      ].some((key) => state.shipping[key] === "");
    },
    paymentValid(state): boolean {
      return (
        !["ccNumber", "ccExp", "ccCvc", "ccZipcode"].some(
          (key) => state.payment[key] === ""
        ) &&
        state.payment.ccNumber.length > 12 &&
        state.payment.ccExp.length === 5 &&
        state.payment.ccCvc.length >= 3 &&
        state.payment.ccZipcode >= 5
      );
    },
    maskedCreditCardNumber(state): string {
      if (!state.payment.ccNumber || state.payment.ccNumber?.length < 5) {
        return "";
      }
      return (state.payment.ccNumber as string).substring(
        state.payment.ccNumber.length - 4
      );
    },
    selectedPlan(state): any {
      if (!state.selectedAvailablePlanId) {
        return null;
      }
      return state.availablePlans.find(
        (p) => p.sku === state.selectedAvailablePlanId
      );
    },
    accountAsApiAccount(state): Account {
      return {
        serviceZipCode: state.coverageZipcode,
        firstName: state.account.firstName,
        lastName: state.account.lastName,
        addressLine1: state.account.address1,
        addressLine2: state.account.address2,
        city: state.account.city,
        state: state.account.state,
        zip: state.account.zipcode,
        email: state.account.email,
        krReferralId: state.referralId,
        krFuelpointsCardNo: state.account.loyaltyNumber,
        customerPassword: state.account.passcode,
        securityQuestion1: state.account.selectedSecurityQuestion,
        securityQuestionAns1: state.account.selectedSecurityQuestionAnswer,
        captchaResponse: state.captchaVerification.token
      } as Account;
    },
  },
  actions: {
    async loadPlans(): Promise<void> {
      this.availablePlans = (await getPlans()) as Plan[];
    },
  },
});
