<script lang="ts">
export default {
  props: {
    bgColor: {
      default: "#D8D8D8",
    },
    fgColor: {
      default: "#FFFFFF",
    },
  },
};
</script>
<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="34">
    <g transform="translate(0, .651685)">
      <path
        :fill="bgColor"
        d="M10.5348111,33 C17.5580185,21.9020178 21.0696222,14.4136215 21.0696222,10.5348111 C21.0696222,4.71659559 16.3530266,0 10.5348111,0 C4.71659559,0 8.8817842e-15,4.71659559 8.8817842e-15,10.5348111 C8.8817842e-15,14.4136215 3.5116037,21.9020178 10.5348111,33 Z"
      ></path>
      <circle :fill="fgColor" cx="10.5" cy="10.5" r="5.5"></circle>
    </g>
  </svg>
</template>
