

<script setup lang="ts">
import { useRouter } from "vue-router";
import CardItem from "./CardItem.vue";
import IconMarker from "./icons/IconMarker.vue";
import { useApplicationStore } from "@/stores/application";
import PromotionCard from "./PromotionCard.vue";
import Glide from '@glidejs/glide';


import { onMounted, ref } from "vue";
import { getPromotions } from "@/api/api";
import type { Promotion } from "@/api/promotion.dto";


const router = useRouter();

const applicationStore = useApplicationStore();

const promotions = ref([] as Promotion[]);

getPromotions().then(data => {
  promotions.value = data;
  setTimeout(()=>{
    new Glide('.glide', {
      type: 'slider ',
      //type: 'carousel',
      perTouch: 2,
      startAt: 0,
      perView: promotions.value.length > 2 ? 2.1 : 2,
      breakpoints: {
        992: {
          perView: 1.25,
          perTouch: 1
        }
      }
  }).mount();
  },0);
})



function checkZip() {
  applicationStore.$patch({
    skipBYOStep: false,
    destinationLink: undefined
  });
  router.push({
    path: "/application",
  });
}

const krSession: {
  needSim: string;
  activNo: string;
  krogerReferralId?: string;
} = {
  needSim: "Y",
  activNo: "",
  krogerReferralId: applicationStore.referralId,
};
const kwShopUrl = import.meta.env.VITE_KW_SHOP_URL;
const viewAllPhones = `${kwShopUrl}&kr_session=${btoa(
  JSON.stringify(krSession)
)}`;

function seeAllPhones() {

  applicationStore.$patch({
    skipBYOStep: true,
    destinationLink: viewAllPhones
  })
  router.push({
    path: "/application",
  });
}



</script>

<template>
  <div class="row wrap">
    <div class="container">
      <div class="introduction">
        <h1>Two Easy Ways to Get Started</h1>
        <h2>Switch to Kroger Wireless and save money on your cellphone bill and fuel!</h2>
        <!-- <h1>New Phone + Plan Bundles</h1>
        <h2>Affordable prepaid cell phone service with Unlimited Data, Talk, Text & Hotspot.</h2> -->
      </div>
      
      <div class="glide container"
           style="">
        <div class="glide__track"
             data-glide-el="track"
             style="padding:2rem;">
          <ul class="glide__slides">
            <li class="glide__slide"
                v-for="promotion in promotions">
              <promotion-card
                              :key="promotion.id"
                              :content="promotion.content"
                              :pricingContent="promotion.pricingContent"
                              :cta="promotion.callToAction"
                              :image="promotion.image.url"
                              :destinationLink="promotion.destinationLink"
                              :skipBYOStep="promotion.skipBYOStep"
                              :offerMessage="promotion.offerMessage"></promotion-card>
            </li>

          </ul>
        </div>
        <!-- <div class="glide__arrows" data-glide-el="controls">
          <button class="glide__arrow glide__arrow--left" data-glide-dir="<">&lt;</button>
          <button class="glide__arrow glide__arrow--right" data-glide-dir=">">&gt;</button>
        </div> -->
        <div data-glide-el="controls[nav]" v-if="promotions.length > 1" :class="{ 'visible-lg' : promotions.length > 2, 'visible-sm' : promotions.length > 1 }">
          <button :data-glide-dir="'=' + index" v-for="(promotion, index) in promotions"></button>
        </div>
        
      </div>

  </div>
    <div class="container all-phones">
      <div>
        <h2>Want to see more options?</h2>
        <p>
          <button @click="seeAllPhones()">View All Phones</button>

        </p>

      </div>

    </div>
  </div>

  <div class="banner">
    <div class="container">

      <div class="callout">
        <!-- <h2>Kroger Wireless</h2> -->
        <h2>Bring Your Own Phone.</h2>
        <h3>As low as, </h3>
        <p class="pricing"><span>$</span><span>15</span><span>/month</span></p>
        <h3>Start saving on your cell phone bill.</h3>
        <h3>Unlimited cell phone coverage for less.</h3>

        <!-- <p class="pricing" data-v-44ff2120=""><span data-v-44ff2120="">$</span><span data-v-44ff2120="">15</span><span data-v-44ff2120="">/month</span></p>-->


      </div>
      <hr class="hr-mobile">
      <div class="kw-card-wrapper">
        <!-- <CardItem class="kw-card--full"> -->
        <!-- <h3>Start Saving on your Cell Phone Bill</h3> -->
        <p>
          Enter your ZIP code below to confirm Kroger Wireless offers service
          in your area.
        </p>
        <div class="flex">
          <div class="input-group">
            <span class="input-prepend">
              <IconMarker />
            </span>
            <input class="input-zipcode"
                   type="text"
                   :value="applicationStore.coverageZipcode"
                   @input="
                     (event) =>
                       applicationStore.$patch({
                         destinationLink: undefined,
                         coverageZipcode: event.target.value,
                       })
                   "
                   placeholder="Zip Code" />
          </div>
          <button class="flex-shrink-0"
                  @click="checkZip()"
                  :disabled="applicationStore.coverageZipcode.length < 5">
            Let's Go
          </button>
        </div>
        <p>
          <i id="existing-customers">Great deals for existing customers!
            <a href="/Login/">Log in here.</a></i>
        </p>
        <!-- </CardItem> -->
        <!-- <p class="disclaimer">
            <i
              >No hidden fees. No contracts. See full terms
              <a href="/support/terms-and-conditions" style="color: currentColor"
                >here.</a
              ></i
            >
          </p> -->
      </div>

    </div>

  </div>
</template>

<style scoped lang="scss">

.glide {
  padding:2rem;
  @media (max-width:992px) {
    padding-top:0;
    position: relative;
    min-width:100vw;
    //left:-1rem;
  }
  &__arrows {
    @media (max-width: 768px) {  
      //display:none;
    }
  }
  &__arrow {
    background-color: rgba(0,0,0,0.45);
    //border-radius: 0;
    //border:0;
    padding-bottom:14px;
  }
  [data-glide-el='controls[nav]'] {
    display:none;

    
    &.visible-lg {
      display:block;
    }
    
    
    @media (max-width:992px) {
    &.visible-sm {
      display:block;
    }
    }    
    
    padding-top:1rem;
    text-align: center;
    button {
      border:none;
      background-color: rgba(255,255,255,.5);
      width:1rem;
      height:1rem;
      margin-left:1rem;
      padding:0;
      border-radius: .5rem;;
      &.glide__bullet--active {
        background-color: rgba(255,255,255,.95)
      }
    }
  }
}
.glide__track {
  padding: 0 !important;
}
.glide__slides {
  //width: 100vh;
}
.wrap {
  background-color: var(--kw-gray-100);
  position: relative;
  padding: 0 5%;
  background: linear-gradient(0deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .1)), url('@/assets/media/banner-bg-small.webp');
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 10rem;

  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

}

.introduction {
  text-align: center;
  padding-top: 2.5rem;

  h1,
  h2,
  p {
    color: rgb(68, 68, 68);
    color: #fff;
  }

  h1 {
    font-size: 42px !important;
    font-weight: 800 !important;
    letter-spacing: 0.36px !important;
    line-height: 59px !important;
  }

  h2 {
    font-size: 20px !important;
    font-weight: normal !important;
    letter-spacing: 0 !important;
    line-height: 28px !important;
  }

  @media (max-width:992px) {
    h1 {
      line-height:2.5rem !important;
    }
  }
}

.promotions {
  display: flex;
  justify-content: space-evenly;
  padding-top: 1rem;

  gap: 4.5rem;

  
  .promotion {
    
  }
}

.container.all-phones {

  padding-top: 0rem;
  padding-bottom: 2rem;

  div {
    text-align: center;
    background-color: var(--kw-white);
    border-radius: 10px;
    padding: 1rem 3rem;

    //margin: 2rem  ;
    h2 {
      color: #000;
      font-size: 2rem;
    }

    button {
      padding-left: 5rem;
      padding-right: 5rem;
    }
  }
}

.banner {
  position: relative;
  top: -13rem;
  padding-top: calc(var(--kw-spacer-y) * 3);
  padding-bottom: calc(var(--kw-spacer-y) * 3);
  margin: 0 5%;

  .container {
    background: var(--kw-blue);
    color: var(--kw-white);
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    //gap: var(--kw-spacer-x);
    //gap:3rem;

    border-radius: 10px;
    padding: 3rem 5rem !important;

    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.14);
    box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.20);

    @media (max-width: 768px) {
      padding: 25px var(--kw-spacer-x) !important;
    }

    .kw-card-wrapper {
      width: 475px;
      max-width: 100%;
      flex: 0 0 50%;
      text-align: center;

      a {
        color: var(--kw-white) !important;
      }

      #existing-customers {
        font-size: 1.25rem;
      }

      .flex {
        >* {
          flex: 0 0 calc(50% - var(--kw-spacer-x) / 2) !important;
          width: 50% !important;

          @media (max-width: 768px) {
            flex: 0 0 100% !important;
            width: 100% !important;
          }
        }
      }

      .input-zipcode {
        width: 180px;
      }
    }
  }

  .disclaimer {

    text-align: center;
    color: var(--kw-white) !important;

    a {
      color: var(--kw-white) !important;
    }
  }

  .callout {
    flex: 0 0 50%;
    color: var(--kw-white) !important;

    h2 {
      font-size: 2.5rem;
      color: var(--kw-white) !important;
      margin-bottom: 30px !important;
    }

    h3 {
      font-size: 1.25rem !important;
      color: var(--kw-white) !important;
      font-weight: 500 !important;
      margin: 0 0 5px 0 !important;
      padding: 0 !important;
    }

    p {
      font-size: 26px;
      color: var(--kw-white) !important;

      &.pricing {
        font-weight: bold;
        display: flex;

        span:first-child {
          font-size: 40px;
          align-self: flex-start;
        }

        span:nth-child(2) {
          font-size: 90px;
          line-height: 1;
          align-self: flex-end;
        }

        span:last-child {
          font-size: 18px;
          align-self: flex-end;
        }

        @media (max-width:992px) {
          justify-content: center;
        }
      }
    }

    .disclaimer {
      font-size: 1rem;
    }

    @media (max-width: 768px) {

      h2,
      h3 {
        text-align: center;
      }

      p.pricing {
        span:first-child {
          font-size: 60px;
        }

        span:nth-child(2) {
          font-size: 120px;
        }

        span:last-child {
          font-size: 24px;
        }
      }
    }
  }

  .hr-mobile {
    width: 100%;
    display: none;
  }

  @media (max-width: 768px) {
    .hr-mobile {
      display: block;
    }
  }
}</style>
